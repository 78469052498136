import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../components/Layout'
import ImageCard from '../../components/ImageCard'

export default function projects({ data }) {
    const projects = data.projects.nodes;  //Access the markdown data
    // const contact = data.contact.siteMetadata.contact;     //Access the contact info

    return (
        <Layout>
            <section className=''>
                <div className='container'>
                <h1>Projects</h1>
                <div className='row'>
                        {projects.map(project => (
                            ImageCard(
                                "/projects/" + project.frontmatter.slug,
                                project.id,
                                project.frontmatter.img,
                                project.frontmatter.title,
                                project.frontmatter.categories
                            )
                        ))}
                </div>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query ProjectsPage {
        projects: allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
            nodes {
                id
                frontmatter {
                    slug
                    title
                    categories
                    img {
                        childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 400, height: 400)
                        }
                    }
                }
            }
        }
        ct: site {
            siteMetadata {
                contact
            }
        }
    }
`